html, body, #root {
  height: 100%;
  color: #fff;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Moul-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, input, p, textarea {
  color: var(--text-lv1-color);
  margin: 0;
  outline: none;
  touch-action: manipulation;
  -webkit-appearance: none;
}